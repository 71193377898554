import React, { Component } from 'react';
import "./Offer_01.css";
import "./Offer_05.css";
import { Link } from "react-scroll";
import Fade from 'react-reveal/Fade';
import Gift from "./gift.png";
import Head from "./head.png";
import Compass from "./compass.png";
import Pin from "./pin.png";
import Watch from "./watch.png";
import People from "./people.png";
import Person1 from "./personCloud.png";
import Person2 from "./person2.png";
import Logo from "./logo.png";
import Banner from './Banner';

const positionName = "Intelligent Cloud and Infrastructure Analyst";

class Offer05 extends Component {

    state = {
        isExtraVisible: false,
        applied: false,
        lang: 'eng'
    }

    handleExtra = () => {
        this.setState({ isExtraVisible: !this.state.isExtraVisible });
        console.log(this.state.isExtraVisible);

    }

    handleScroll = e => {

        const body = document.body,
            html = document.documentElement;

        const documentHeight = Math.max(body.scrollHeight, body.offsetHeight,
            html.clientHeight, html.scrollHeight, html.offsetHeight);

        const currentScrollValue = document.documentElement.scrollTop;

        const applyBanner = document.getElementById("applyBanner");

        if (currentScrollValue < .65 * documentHeight) {
            applyBanner.classList.remove("invisible");
        } else {
            applyBanner.classList.add("invisible");
        }

    }

    closeBanner = () => {
        this.setState({ applied: false });
    }

    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll);
        window.scrollTo(0, 0);
        document.title = "Accenture | " + positionName;

        if (this.props.location.search.includes("success=true")) {
            this.setState({ applied: true });
        }
        if (this.props.location.search.includes("lang=pl")) {
            this.setState({ lang: 'pl' });
        }
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    }

    render() {
        return (
            <>
                <div className="offer offer05">
                    {this.state.applied && <Banner close={this.closeBanner} lang={this.state.lang} />}

                    <div className="offer__apply offer__applyA" id="applyBanner">
                        <div className="section__wrap">
                            <img className="offer__image" src={Logo} alt="Accenture Logo" />
                            <Link to="contact" spy={true} smooth={true} offset={0} duration={500}>
                                <span href="#contact"><button className="offer__button">Apply</button></span>
                            </Link>
                        </div>

                    </div>

                    <div className="offer__header">
                        <div className="offer__titles offer__wrap">
                            <img className="offer__person1" src={Person1} alt="person" />
                            {/* <img className="offer__image" src={Sign} alt="Accenture Logo" /> */}
                            {/* <Fade up> */}
                            <h1 className="offer__title">INTELLIGENT CLOUD</h1>
                            <h1 className="offer__title">& INFRASTRUCTURE</h1>
                            <h1 className="offer__title">ANALYST</h1>
                            {/* </Fade> */}
                            <div className="header__info">
                                <div className="row">
                                    <Fade left>
                                        <img src={Pin} alt="pin sign" />
                                    </Fade>
                                    <p>Warsaw</p>
                                </div>
                                <div className="row">
                                    <Fade left>
                                        <img src={Watch} alt="watch sign" />
                                    </Fade>
                                    <p>Full time</p>
                                </div>
                                <div className="row">
                                    <Fade left>
                                        <img src={People} alt="people sign" />
                                    </Fade>
                                    <p>Junior/Mid</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className="section offer__benefits">
                        <div className="section__wrap">
                            <div className="section__content">
                                {/* <Fade down> */}
                                <h2 className="offer__subtitle">What we can offer</h2>
                                {/* </Fade> */}
                                <div className="section__table">
                                    {/* <Fade right> */}
                                    <div className="section__row-text">Clearly defined career path</div>
                                    {/* </Fade> */}
                                    {/* <Fade right> */}
                                    <div className="section__row-text">Lunch vouchers</div>
                                    {/* </Fade> */}
                                    {/* <Fade right> */}
                                    <div className="section__row-text">Attractive career opportunities</div>
                                    {/* </Fade> */}
                                    {/* <Fade right> */}
                                    <div className="section__row-text">Sports tickets</div>
                                    {/* </Fade> */}
                                    {/* <Fade right> */}
                                    <div className="section__row-text">In-house training, workshops, courses </div>
                                    {/* </Fade> */}
                                    {/* <Fade right> */}
                                    <div className="section__row-text">Additional life insurance</div>
                                    {/* </Fade> */}
                                    {/* <Fade right> */}
                                    <div className="section__row-text">International projects</div>
                                    {/* </Fade> */}
                                    {/* <Fade right> */}
                                    <div className="section__row-text">Cyclical integration meetings</div>
                                    {/* </Fade> */}
                                    {/* <Fade right> */}
                                    <div className="section__row-text">Private medical care</div>
                                    {/* </Fade> */}
                                    {/* <Fade right> */}
                                    <div className="section__row-text">American Express Gold corporate credit card</div>
                                    {/* </Fade> */}
                                    {/* <Fade right> */}
                                    <div className="section__row-text">Mentoring</div>
                                    {/* </Fade> */}
                                    {/* <Fade right> */}
                                    <div className="section__row-text">Access to e-learning platform</div>
                                    {/* </Fade> */}
                                    {/* <Fade right> */}
                                    <div className="section__row-text">Certifications</div>
                                    {/* </Fade> */}
                                    {/* <Fade right> */}
                                    <div className="section__row-text">Training budget</div>
                                    {/* </Fade> */}
                                </div>
                            </div>
                            <Fade right>
                                <div className="section__image-wrap">
                                    <img className="section__image" src={Gift} alt="Gift icon" />
                                </div>
                            </Fade>
                        </div>
                    </section>

                    <section className="section offer__details">
                        <div className="section__wrap">
                            <img className="offer__person2" src={Person2} alt="person" />
                            <div className="offer__subtitles">
                                <p className="details__sentence">The Intelligent Cloud and Infrastructure team helps clients transform their IT infrastructure using comprehensive, intelligent infrastructure
    solutions, including cloud, data centers, security solutions and service management. We support digital strategies of our clients by
designing and implementing flexible IT solutions.</p>
                                {/* <Fade left>
                                    <h2 className="offer__subtitle">Let's improve</h2>
                                </Fade>
                                <Fade left>
                                    <h2 className="offer__subtitle">the world</h2>
                                </Fade>
                                <Fade left>
                                    <h2 className="offer__subtitle green">together</h2>
                                </Fade> */}
                            </div>
                        </div>
                    </section>

                    <section className="section offer__tasks">
                        <div className="section__wrap">
                            <div className="section__content">
                                {/* <Fade down> */}
                                <div className="offer__subtitles">
                                    <h2 className="offer__subtitle">Your daily tasks</h2>
                                </div>
                                {/* </Fade> */}
                                <div className="section__table">
                                    <div className="section__row">
                                        <Fade left>
                                            <div className="section__row-image"><p>1</p></div>
                                        </Fade>
                                        {/* <Fade left> */}
                                        <div className="section__row-text">Supporting delivery of IT solutions in cloud, hybrid and on-premises model for Enterprise customers – banks, telecoms, corporations.</div>
                                        {/* </Fade> */}
                                    </div>
                                    <div className="section__row">
                                        <Fade left>
                                            <div className="section__row-image">2</div>
                                        </Fade>
                                        {/* <Fade left> */}
                                        <div className="section__row-text">Participating in implemetation and supporting design of technical solutions in the fields of IT architecture, automation, Disaster Recovery / High Availability for IaaS, PaaS, SaaS, IDaaS, CaaS.</div>
                                        {/* </Fade> */}
                                    </div>
                                    <div className="section__row">
                                        <Fade left>
                                            <div className="section__row-image">3</div>
                                        </Fade>
                                        {/* <Fade left> */}
                                        <div className="section__row-text">IT Operational hand-over.</div>
                                        {/* </Fade> */}
                                    </div>
                                    <div className="section__row">
                                        <Fade left>
                                            <div className="section__row-image">4</div>
                                        </Fade>
                                        {/* <Fade left> */}
                                        <div className="section__row-text">Identifying the possibilities of optimization and transformation of client IT infrastructure.</div>
                                        {/* </Fade> */}
                                    </div>
                                    <div className="section__row">
                                        <Fade left>
                                            <div className="section__row-image">5</div>
                                        </Fade>
                                        {/* <Fade left> */}
                                        <div className="section__row-text">Initial customer engagement and presales assistance.</div>
                                        {/* </Fade> */}
                                    </div>
                                </div>
                            </div>
                            <Fade right>
                                <div className="section__image-wrap">
                                    <img className="section__image" src={Compass} alt="Compass icon" />
                                </div>
                            </Fade>
                        </div>
                    </section>

                    <section className="section offer__expect">
                        <div className="section__wrap">
                            <div className="section__content">
                                {/* <Fade down> */}
                                <h2 className="offer__subtitle one">What we're</h2>
                                <h2 className="offer__subtitle two">looking for</h2>
                                {/* </Fade> */}
                                <div className="must-have">
                                    {/* <Fade left>
                                        <h3>Must have</h3>
                                    </Fade> */}
                                    {/* <Fade left> */}
                                    <div className="features">
                                        <p className="feature">SQL</p>
                                        <p className="feature">R</p>
                                        <p className="feature">Python</p>
                                        <p className="feature">business analysis</p>
                                        <p className="feature">data analysis</p>
                                        <p className="feature">analytical methods</p>
                                        <p className="feature">mobility</p>
                                        <p className="feature">creativity</p>
                                        <p className="feature">problem solving</p>
                                        <p className="feature">communication skills</p>
                                        <p className="feature">Power BI</p>
                                        <p className="feature">higher education</p>
                                    </div>
                                    {/* </Fade> */}
                                </div>
                            </div>
                            <Fade right>
                                <div className="section__image-wrap">
                                    <img className="section__image" src={Head} alt="Head icon" />
                                </div>
                            </Fade>
                        </div>
                    </section>

                    <section className="section offer__about">
                        <div className="section__wrap">
                            <div className="section__video">
                                <iframe src="https://www.youtube.com/embed/ux7Ef5-0g5A?autoplay=0&mute=0&controls=0&origin=https%3A%2F%2Fworkate.pl&playsinline=1&showinfo=0&rel=0&iv_load_policy=3&modestbranding=1&enablejsapi=1&widgetid=1" frameBorder="0" scrolling="no" title="About Accenture"></iframe>
                            </div>
                        </div>
                    </section>

                    <section className="section offer__contact" id="contact">
                        <div className="section__wrap">
                            <div className="offer__subtitles">
                                {/* <Fade down> */}
                                <h2 className="offer__subtitle">Let's join forces!</h2>
                                {/* </Fade> */}
                                {/* <Fade down> */}
                                <h2 className="offer__subtitle green">Apply now!</h2>
                                {/* </Fade> */}
                            </div>
                            <div className="form__wrap">
                                <form className="form" id="formOfferA" method="POST" action="index.php">
                                    <input type="hidden" name="offer" value={positionName} />
                                    {/* <Fade up> */}
                                    <input placeholder="Fist name" name="firstName" required />
                                    {/* </Fade> */}
                                    {/* <Fade up> */}
                                    <input placeholder="Last name" name="lastName" required />
                                    {/* </Fade> */}
                                    {/* <Fade up> */}
                                    <input type="email" placeholder="Email" name="email" required />
                                    {/* </Fade> */}
                                    {/* <Fade up> */}
                                    <input type="number" placeholder="Phone number" name="phoneNumber" required />
                                    {/* </Fade> */}
                                    <input type="hidden" name="type" value="/Only applicable for the general form./" />
                                    {/* <Fade up> */}
                                    <select name="city" id="city" defaultValue={"DEFAULT"}>
                                        <option value="DEFAULT" disabled>City</option>
                                        <option value="Warsaw">Warsaw</option>
                                    </select>
                                    {/* </Fade> */}
                                    <input type="hidden" name="profile" value="-" />
                                    {/* <Fade up> */}
                                    <label className="firstLabel">
                                        <input type="checkbox" required />
                                        <span>I hereby consent to the processing of my personal data by Accenture sp. z o.o. with its registered seat in Warsaw (00-121), at ul. Sienna 39, NIP 526-00-15-900 and Higher Sp. z o.o. with its registered seat in Warsaw (00-079), at ul. Krakowskie Przedmieście 79/300 (Data Controllers), in accordance with the Act of August 29, 1997 on the Protection of Personal Data (Journal of Laws of 2016, item 922)<span className={this.state.isExtraVisible ? "extra visible" : "extra"}> and the Regulation on the protection of natural persons with regard to the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC (Data Protection Directive), necessary to carry out the recruitment process by Accenture. At the same time, I declare that I provide my personal data completely voluntary. I also declare that I have been informed about my right to withdraw my consent or object to processing of data, request access to them, rectification, deletion, limitation of processing and their transfer, at any time and the right to lodge a complaint to the data protection supervisory authority</span>.</span>
                                    </label>
                                    <div className="extra-toggler" onClick={this.handleExtra}>{this.state.isExtraVisible ? "Show less" : "Show more"}</div>
                                    {/* </Fade> */}
                                    {/* <Fade up> */}
                                    <label>
                                        <input type="checkbox" required />
                                        <span>I have read, understood and accept <a href="https://accenture.higher.pl/polityka-prywatnosci?fbclid=IwAR0-YnRLr9nGefqotOTOW7tC2KAfyUkEQGyJwk3asLuCK6vR7VGGicKVemc" target="_blank" rel="noopener noreferrer">the Terms and Regulations.</a></span>
                                    </label>
                                    {/* </Fade> */}
                                    {/* <Fade up> */}
                                    <input className="offer__button" id="send_application" name="submit_offer_new" type="submit" value="Send" />
                                    {/* </Fade> */}
                                </form>
                                <div className="image__wrap"></div>
                            </div>
                        </div>
                    </section>

                    <footer className="offer__footer">
                        <div className="section__wrap">
                            <div className="footer__socials">
                                <a href="https://www.linkedin.com/company/accenturepoland?trk=vsrp_companies_cluster_name&trkInfo=VSRPsearchId%3A220393721475495038518%2CVSRPtargetId%3A15154437%2CVSRPcmpt%3Acompanies_cluster?" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin-in"></i></a>
                                <a href="https://m.facebook.com/AccentureKariera/" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f"></i>
                                </a>
                                <a href="https://twitter.com/Accenture" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter"></i>
                                </a>
                                <a href="https://m.youtube.com/user/AccenturePoland" target="_blank" rel="noopener noreferrer"><i className="fab fa-youtube"></i></a>
                            </div>
                        </div>
                    </footer>
                </div>
            </>
        );
    }

}

export default Offer05;